<template>
  <b-container class="container-header mb-4" fluid>
    <b-row class="h-100">
      <b-col>
        <div v-if="leftBtn" class="h-100 d-flex align-items-center left-btn">
          <p class="mb-0 active-element" @click="$emit('clickLeft')">{{ leftBtn }}</p>
        </div>
      </b-col>
      <b-col class="d-flex align-items-center justify-content-center">
        <div class="logo-block" @click="$router.push({name: 'Home'})">
          <logo/>
        </div>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>
<script>
import Logo from './Logo.vue';

export default {
  components: {
    Logo,
  },
  props: [
    'title',
    'leftBtn',
  ],
};
</script>
<style lang="scss" scoped>
@import '~@/assets/sass/lbd/mixins/layout.scss';

.container-header {
  height: 90px;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  .left-btn {
    p {
      font-size: 21px;
      font-weight: 400;
      color: #435451;
      padding-left: 20px;
      @include respond-to(sm) {
        font-size: 18px;
      }
      @include respond-to(xs) {
        font-size: 15px;
        padding-left: 10px;
      }
    }
  }

  .logo-block {
    margin: 40px 0;

    svg {
      width: 175px;
    }

    cursor: pointer;
  }
}
</style>
